<template>




    <div>

        <div class="header" id="header">
            <!-- <img src="../../assets/img/ywllogo.png" alt="" class="logo"> -->
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>



        <div class="banner">
            <img src="../../assets/img/gyimg.png" alt="">
        </div>

        <div class="navbox">
            <div class="nav">
                <div class="navitem navactive">本所简介</div>
                <div class="navitem">企业文化</div>
                <div class="navitem">联系我们</div>
                <!-- <div class="navitem">参股流程</div> -->
                <!-- <div class="navitem">影视资讯</div> -->
                <div class="navxian"></div>
            </div>
        </div>



    <!-- <div class="videobox">
        <div class="boxtitle">
            宣传影片
            <img src="../../assets/img/titlebg.png" alt="">
        </div>
        <div class="videobox2">
            <video id="myvideo" style="object-fit: fill;" preload="auto" poster="https://yhscimg.hbyzys.cn//videoposter.png"
                x5-video-player-fullscreen="true" webkit-playsinline="true" x-webkit-airplay="true" playsinline="true"
                x5-playsinline="true">
                <source src="http://img.hbyzys.cn/shipinjs/xuanchuangs.mp4" type="video/mp4">
            </video>

            <img src="../../assets/img/playicon.png" alt="" id="mp4play">
        </div>
    </div> -->

        <img class="jieshaoimg" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/矩形514(1)_1685350660128.png">

    <div class="gywmbox">
        <div class="boxtitle">
            本所简介
        </div>
        <div class="gytxt">湖北华中文化产权交易所（简称“华中文交所”）于2010年9月经湖北省人民政府批准设立，中宣部、国务院要素市场领导小组审核通过，是以文化物权、债权、股权、知识产权等为交易对象的文化资本要素市场平台，是国内交易品种最多、功能最全的文化产权交易机构之一；是中国文化产权交易协会常务理事单位、中国版权协会常务理事单位、全国文化金融联盟理事单位、2022年度全国版权示范单位。</div> 
<div class="gytxt">国家和湖北省高度重视华中文交所的发展，先后出台多项重磅政策明确“推动湖北华中文化产权交易所建设成为中部地区文化产品创新要素市场”、“支持华中文化产权交易所做大做强，探索建立文化金融服务中心”等。</div> 
<div class="gytxt">按照湖北省委省政府对文化产权市场的功能定位，近年来，华中文交所全力打造“文化要素交易+文化金融服务+数字文化创新”的三驾马车发展模式，充分发挥资源配置、投融资、产业孵化“三大”功能，着力构建国有资产交易、文化艺术品交易、文化金融服务、数字文化创新、文化产业发展等“五大”平台，助力全省文化产业高质量发展。</div> 
<div class="gytxt">国家和湖北省高度重视华中文交所的发展：</div> 
<div class="gytxt">《国家促进中部地区崛起“十三五”规划》指出“推动湖北华中文化产权交易所建设成为中部地区文化产品创新要素市场”；</div> 
<div class="gytxt">《湖北省国民经济和社会发展第十三个五年规划纲要》指出“做大做强湖北华中文化产权交易所，设立文化产业投资基金，搭建文化产业发展投融资平台”。</div> 
<div class="gytxt">《中共湖北省委湖北省政府关于加快全省文化产业高质量发展的意见》（鄂发〔2018〕30号）指出“推动文化产权市场创新发展，支持华中文化产权交易所做大做强，探索建立文化金融服务中心”；</div> 
<div class="gytxt">《省人民政府办公厅关于印发支持文化旅游产业恢复振兴若干措施的通知》(鄂政办发〔2020〕17号)指出“用好华中文交所互联网+金融服务平台，为文旅企业提供投融资服务”。</div> 
        

        
    </div>



    <div class="fzlcbox">
        <div class="boxtitle">
            企业文化
        </div>
        <!-- <img src="../../assets/img/fzlcbg.png" alt="" class="fzlcbg">
        <div class="fzlcbox1">
            <div class="fzlcxian"></div>
            <div class="fzlcbox1item fzlcbox1item1 fzlcbox1itemactive">
                <div class="fzlcbox1itemdian"> <div></div> </div>
                2017年
            </div>
            <div class="fzlcbox1item fzlcbox1item2">
                <div class="fzlcbox1itemdian"> <div></div> </div>
                2018年
            </div>
            <div class="fzlcbox1item fzlcbox1item3">
                <div class="fzlcbox1itemdian"> <div></div> </div>
                2019年
            </div>
            <div class="fzlcbox1item fzlcbox1item4">
                <div class="fzlcbox1itemdian"> <div></div> </div>
                2020年
            </div>
            <div class="fzlcbox1item fzlcbox1item5">
                <div class="fzlcbox1itemdian"> <div></div> </div>
                2020年
            </div>

            <div class="fzlcbox1txt">
                <div class="fzlcbox1txt1">
                    <p>引未来事迹</p>
                    <div class="fzlcbox1txts">2017年，引未来初创团队与深圳文化产权交易所合作，成立当代字画交易中心。</div>
                </div>
                <div class="fzlcbox1txt1" style="display: none">
                    <p>引未来事迹</p>
                    <div class="fzlcbox1txts">2018年，引未来先后从光线影业，博纳影业、联瑞影业等顶级出品、制作、发行团队引进大批行业领军人才组成投研团队。</div>
                </div>
                <div class="fzlcbox1txt1" style="display: none">
                    <p>引未来事迹</p>
                    <div class="fzlcbox1txts">2019年，引未来正式进军影视文娱行业，公司坐落武汉光谷。</div>
                </div>
                <div class="fzlcbox1txt1" style="display: none">
                    <p>引未来事迹</p>
                    <div class="fzlcbox1txts">2020年，引未来涉及的范围由电影出品扩大到院线电影项目的策划、参投，致力于为更多的人群带去更为优质的电影作品。</div>
                    <div class="fzlcbox1txts">先后参投了多部票房超过10亿的影片。</div>
                </div>
                <div class="fzlcbox1txt1" style="display: none">
                    <p>引未来事迹</p>
                    <div class="fzlcbox1txts">2021年，武汉引未来文化传媒有限公司已经与全国多个地区的多家影视参投、制作和出品公司建立了良好的合作伙伴关系。</div>
                    <div class="fzlcbox1txts">依靠科技赋能，整合文娱产业上下游，发展智能影视宣发业务。</div>
                </div>
            </div>
        </div> -->

        <div class="gyyzbox2">
            <div class="gyitem">
                <img src="../../assets/img/gyicon1.png" alt="">
                <p class="gyitemp">愿 景</p>
                <p>打造全国一流的<br/>文化要素交易平台</p>
            </div>
            <div class="gyitem">
                <img src="../../assets/img/gyicon2.png" alt="">
                <p class="gyitemp">使 命</p>
                <p>以文弘业 交易赋能<br/>发现价值 成就精彩</p>
            </div>
            <div class="gyitem">
                <img src="../../assets/img/gyicon3.png" alt="">
                <p class="gyitemp">价值观</p>
                <p>诚信 团结<br/>精进 励新</p>
            </div>
        </div>
    </div>








    <div class="zytdbox">
        <div class="boxtitle">
            联系我们
        </div>
        <div class="contactUsList">

            <div class="contactUsItem">
                <p class="contactUsItemP1">影视产权交易平台</p>
                <p class="contactUsItemP2">联系电话</p>
                <p class="contactUsItemP2">027-87888840</p>
                <!-- <img class="contactUsItemJT" @click="changeContactUsItem(0)" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/路径414_1684735499306.png"> -->
            </div>
            
        </div>
    </div>

    <Foot />

    <div class="toGrzx" @click="toGrzx">
        <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
    </div>

    
    </div>


</template>


<script>
import Foot from "@/components/foot.vue"
import Cookies from 'js-cookie'
import {getListColumn} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'
export default {
    components:{
        Foot
    },
    data(){
        return {
            topnavaindex : 0,
            navtop : null,
            t : null,
            personlist : null,
            qalist : null,
            articlelist : [
                {
                    "imagePath": "https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Image_1684376599509.png",
                    "articleId": 123,
                    "articleTitle": "《误杀：瞒天过海》电影筹拍预展",
                    "author": "中艺购",
                    "publishTime": "2023-05-08",
                },
            ],
            headerflag : false,
            hiddenflag : false,
            load : null,
            columnData:null,
        }
    },
    computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
    methods:{
        toGrzx(){
            if(!Cookies.get('wjsToken')){
                this.$router.push({name:'dl'});
            }else{
                this.$router.push({name:'grzx'});
            }
        },
        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                this.reset()
                this.$router.push({name:name});
            //}
        },
        toBack(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toyszb(){
            this.reset()
            this.$router.push({name:'yszb'});
        },
        tojrpf(){
            this.reset()
            this.$router.push({name:'jrpf'});
        },
        tohzjg(){
            this.reset()
            this.$router.push({name:'hzjg'});
        },
        tobsxw(){
            this.reset()
            this.$router.push({name:'bsxw'});
        },
        tozcfg(){
            this.reset()
            this.$router.push({name:'zcfg'});
        },
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toaboutus(){
            this.reset()
            this.$router.push({name:'gywm'});
        },
        tonews(){
            this.reset()
            this.$router.push({name:'hydt'});
        },
        tonews2(){
            this.reset()
            document.body.scrollTop = document.documentElement.scrollTop = 0
            this.$router.push({name:'news'});
        },
        togsdt(){
            this.reset()
            this.$router.push({name:'gsdt'});
        },
        toyszx(){
            this.reset()
            this.$router.push({name:'yszx'});
        },
        tohyfx(){
            this.reset()
            this.$router.push({name:'hyfx'});
        },
        tozcjd(){
            this.reset()
            this.$router.push({name:'zcjd'});
        },
        tomovielist(){
            this.reset()
            this.$router.push({name:'xmpl'});
        },
        tongyhd(){
            this.reset()
            this.$router.push({name:'gyhd'});
        },
        touserguide(){
            this.reset()
            this.$router.push({name:'userGuide'});
        },
        tocu(){
            this.reset()
            this.$router.push({name:'connectus'});
        },
        tonews2(){
            this.reset()
            document.body.scrollTop = document.documentElement.scrollTop = 0
            this.$router.push({name:'news'});
        },
        windowscroll(){
                this.t = document.documentElement.scrollTop || document.body.scrollTop;
                var nav = document.getElementsByClassName("nav")[0];
                //var vw = document.documentElement.clientWidth / 7.5;
                //var headheight = document.documentElement.clientWidth / 7.5 * 1.08;
                var topheight = document.documentElement.clientWidth / 7.5 * 1.68;
                var gywmbox = document.getElementsByClassName("gywmbox")[0];
                var gywmboxtop = this.getElementTop(gywmbox) - topheight;
                var fzlcbox = document.getElementsByClassName("fzlcbox")[0];
                var fzlcboxtop = this.getElementTop(fzlcbox) - topheight;
                var zytdbox = document.getElementsByClassName("zytdbox")[0];
                var zytdboxtop = this.getElementTop(zytdbox) - topheight;
                //var videobox = document.getElementsByClassName("videobox")[0];
                //var videoboxtop = this.getElementTop(videobox) - topheight;
                // var newsbox = document.getElementsByClassName("newsbox")[0];
                // var newsboxtop = this.getElementTop(newsbox) - topheight;
                //console.log(t)

                    if( this.t >= this.navtop){
                        nav.classList.add("navfixed")
                    }else{
                        nav.classList.remove("navfixed")
                    }



                    // if(this.t >= videoboxtop - 50 && this.t < gywmboxtop - 50){
                    //     this.topnavaindex = 0;
                    //     this.navactive(this.topnavaindex)
                    // }
                    if(this.t >= gywmboxtop - 50 && this.t < fzlcboxtop - 50){
                        this.topnavaindex = 0;
                        this.navactive(this.topnavaindex)
                    }
                    if(this.t >= fzlcboxtop - 50&& this.t < zytdboxtop - 50){
                        this.topnavaindex = 1;
                        this.navactive(this.topnavaindex)
                    }
                    // if(this.t >= zytdboxtop - 50&& this.t < newsboxtop - 50){
                    //     this.topnavaindex = 2;
                    //     this.navactive(this.topnavaindex)
                    // }
                    // if(this.t >= cglcboxtop - 50&& this.t < cgdyboxtop - 50){
                    //     this.topnavaindex = 3;
                    //     this.navactive(this.topnavaindex)
                    // }
                    if(this.t >= zytdboxtop - 50){
                        this.topnavaindex = 2;
                        this.navactive(this.topnavaindex)
                    }
        },
        getElementTop(el) {
            var actualTop = el.offsetTop
            var current = el.offsetParent
            while (current !== null) {
                actualTop += current.offsetTop
                current = current.offsetParent
            }
            return actualTop
        },
        navactive(Index) {
            var navitem = document.getElementsByClassName("navitem");
            var navxian = document.getElementsByClassName("navxian")[0];
            for (let i = 0; i < navitem.length; i++) {
                navitem[i].classList.remove('navactive')
            }
            navitem[Index].classList.add('navactive')
            navxian.style.left = `calc(0.23rem * ${Index * 2 + 1} + 1.04rem * ${Index} + 0.52rem - 0.23rem)`
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
        this.headerflag = false
        this.hiddenflag = false
        var headericon = document.getElementById("headericon")
        var headerhidden = document.getElementById("headerhidden")
        //var headjt = document.getElementById("headjt")
        //var hiddenbox = document.getElementById("hiddenbox")
        headerhidden.style.top = "-100%"
        headericon.classList.remove("headericon2")
        headericon.classList.add("headericon")
        //hiddenbox.style.height = "0"
        //headjt.style.transform = "rotateZ(0deg)"
    },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        changeContactUsItem(index){
            var contactUsItemJT = document.getElementsByClassName('contactUsItemJT');
            var contactUsItem = document.getElementsByClassName('contactUsItem');
            if(contactUsItemJT[index].classList.contains("contactUsItemJTHide")){
                contactUsItemJT[index].classList = "contactUsItemJT"
                contactUsItem[index].classList = "contactUsItem contactUsItemHide"
            }else{
                contactUsItemJT[index].classList = "contactUsItemJT contactUsItemJTHide"
                contactUsItem[index].classList = "contactUsItem"
            }
        },
    },
    mounted(){
            


            this.showLoading()
            document.body.scrollTop = document.documentElement.scrollTop = 0


            // let head = document.getElementsByTagName('head');
            // let meta = document.createElement('meta');
            // document.querySelector('meta[name="keywords"]').setAttribute('content', "关于我们-湖北引未来影视")
            // document.querySelector('meta[name="description"]').setAttribute('content', "关于我们-湖北引未来影视")
            // meta.content = {
            //     keywords:"关于我们-湖北引未来影视",
            //     description:"关于我们-湖北引未来影视",
            // };
            // head[0].appendChild(meta)
            // document.title = "关于我们-湖北引未来影视";



            // listPerson({
            //     "data": {
            //         "isShow": "1",
            //         "keyWords": ""
            //     },
            //     "mapParam": {}
            // }).then(res => {
            //     this.personlist = res.slice(2,4)

                // listArticle({
                //     "data": {
                //         //"categoryId": 3,
                //         "isHeadline": "1",
                //         "isHot": "1",
                //         "isRecommend": "1",
                //         "isShow": "1",
                //         "isTop": "1",
                //     },
                //     "mapParam": {},
                //     "reqPage": {
                //         "currPageNo": 1,
                //         "pageSize": 3
                //     }
                // }).then(res => {
                //     this.articlelist = res.records

                if(this.columnList){
                    console.log("store" + this.columnList)
                    this.columnData = this.columnList;
                    this.load && this.load.close();
                }else{
                    getListColumn().then(res => {
                        //console.log(res)
                        this.columnData = res.result;

                        store.dispatch('SetColumnList', res.result)


                    })
                }



                    this.$nextTick(() => {
                        this.t = document.documentElement.scrollTop || document.body.scrollTop;
                        var nav = document.getElementsByClassName("nav")[0];
                        //var vw = document.documentElement.clientWidth / 7.5;
                        var headheight = document.documentElement.clientWidth / 7.5 * 1.08;
                        var topheight = document.documentElement.clientWidth / 7.5 * 1.68;
                        this.navtop = getElementTop(nav) - headheight;


                        // var fzlcbox1item = document.getElementsByClassName("fzlcbox1item");
                        // var fzlcbox1txt1 = document.getElementsByClassName("fzlcbox1txt1");

                        var navitem = document.getElementsByClassName("navitem");
                        var navxian = document.getElementsByClassName("navxian")[0];

                        var gywmbox = document.getElementsByClassName("gywmbox")[0];
                        var gywmboxtop = getElementTop(gywmbox) - topheight;
                        var fzlcbox = document.getElementsByClassName("fzlcbox")[0];
                        var fzlcboxtop = getElementTop(fzlcbox) - topheight;
                        var zytdbox = document.getElementsByClassName("zytdbox")[0];
                        var zytdboxtop = getElementTop(zytdbox) - topheight;
                        //var videobox = document.getElementsByClassName("videobox")[0];
                        //var videoboxtop = getElementTop(videobox) - topheight;
                        // var newsbox = document.getElementsByClassName("newsbox")[0];
                        // var newsboxtop = getElementTop(newsbox) - topheight;
                        var topnavaindex = 0


                    
                        // var mp4play = document.getElementById("mp4play")
                        // var myvideo = document.getElementById("myvideo")


                        // mp4play.onclick = function(){
                        //     myvideo.play()
                        //     myvideo.setAttribute("controls","controls")
                        //     mp4play.style.display = "none"
                        // }


                        window.addEventListener('scroll',this.windowscroll)



                        // fzlcbox1item[0].onclick = function(){
                        //     fzlcactive(0)
                        // }
                        // fzlcbox1item[1].onclick = function(){
                        //     fzlcactive(1)
                        // }
                        // fzlcbox1item[2].onclick = function(){
                        //     fzlcactive(2)
                        // }
                        // fzlcbox1item[3].onclick = function(){
                        //     fzlcactive(3)
                        // }
                        // fzlcbox1item[4].onclick = function(){
                        //     fzlcactive(4)
                        // }



                        // navitem[0].onclick = function(){
                        //     $('html,body').animate({
                        //         scrollTop: videoboxtop
                        //     }, 600);
                        // }

                        navitem[0].onclick = function(){
                            $('html,body').animate({
                                scrollTop: gywmboxtop
                            }, 600);
                        }

                        navitem[1].onclick = function(){
                            $('html,body').animate({
                                scrollTop: fzlcboxtop
                            }, 600);
                        }

                        navitem[2].onclick = function(){
                            $('html,body').animate({
                                scrollTop: zytdboxtop
                            }, 600);
                        }

                        // navitem[3].onclick = function(){
                        //     $('html,body').animate({
                        //         scrollTop: cglcboxtop
                        //     }, 600);
                        // }

                        // navitem[3].onclick = function(){
                        //     $('html,body').animate({
                        //         scrollTop: newsboxtop
                        //     }, 600);
                        // }


                        function fzlcactive(index) {
                            for (let j = 0; j < fzlcbox1item.length; j++) {
                                fzlcbox1item[j].classList.remove('fzlcbox1itemactive')
                                fzlcbox1txt1[j].style.display = "none"
                            }
                            fzlcbox1item[index].classList.add('fzlcbox1itemactive')
                            fzlcbox1txt1[index].style.display = "block"
                        }



                        function navactive(Index) {
                            for (let i = 0; i < navitem.length; i++) {
                                navitem[i].classList.remove('navactive')
                            }
                            navitem[Index].classList.add('navactive')
                            navxian.style.left = `calc(0.23rem * ${Index * 2 + 1} + 1.04rem * ${Index} + 0.52rem - 0.23rem)`
                        }
                        

                        



                        //获取元素距离顶部高度
                        function getElementTop(el) {
                            var actualTop = el.offsetTop
                            var current = el.offsetParent
                            while (current !== null) {
                                actualTop += current.offsetTop
                                current = current.offsetParent
                            }
                            return actualTop
                        }


                        this.load && this.load.close();


                    })

                //})

            //})

            



            


        

    },
    beforeDestroy (){
        window.removeEventListener('scroll',this.windowscroll)
    },
    filters: {
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    }
}
</script>


<style lang="scss" scoped>

.jieshaoimg{
    width: 5.58rem;
    height: 3.24rem;
    display: block;
    margin: 0 auto;
    object-fit: cover;
    margin-bottom: .24rem;
}


.banner{
    width: 100%;
    height: 3.5rem;
    background: url(https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组666_1684723213621.png) no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
}
.banner img{
    width: 2.46rem;
    height: 1rem;
    position: absolute;
    top: 0.92rem;
    left: 50%;
    transform: translateX(-50%);
}


.navbox{
    width: 100%;
    height: 0.6rem;
    background-color: #fff;
    position: relative;
    margin-bottom: 0.2rem;
}
.nav{
    width: 100%;
    height: 0.6rem;
    display: flex;
    background-color: #fff;
    position: relative;
    z-index: 997;
    border-bottom: 0.02rem solid rgba(112, 112, 112, .2);
}
.navfixed{
    position: fixed;
    top: 1.08rem;
}
.navitem{
    width: 1.04rem;
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.6rem;
    color: #343434;
    white-space: nowrap;
    padding: 0 0.23rem;
}
.navactive{
    color: #A30001;
}
.navxian{
    width: 0.46rem;
    height: 0.04rem;
    background: #A30001;
    border-radius: 2.4rem;
    position: absolute;
    bottom: -0.01rem;
    left: calc(0.23rem * 1 + 1.04rem * 0 + 0.52rem - 0.23rem);
    transition: .4s ease;
}




.videobox{
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 0.2rem 0.22rem;
    box-sizing: border-box;
}
.videobox .videobox2{
    width: 7.06rem;
    height: 4.12rem;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background: #E9E8EF;
    box-shadow: 0px 0px 0.08rem rgba(39, 38, 37, 0.13);
    border-radius: 10px;
}
.videobox .videobox2 video{
    width: 6.7rem;
    height: 3.78rem;
    margin: 0.14rem 0.18rem;
}
#mp4play{
    width: 0.7rem;
    height: 0.7rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}





.gywmbox{
    width: 100%;
    padding: 0 0.22rem;
    box-sizing: border-box;
    margin-bottom: 0.2rem;
    position: relative;
}
.gywmbg{
    width: 2.72rem;
    height: 5.16rem;
    position: absolute;
    right: 0.24rem;
    top: -1.08rem;
    z-index: -1;
}
.boxtitle{
    font-size: 0.34rem;
    font-weight: bold;
    line-height: 0.48rem;
    color: #343434;
    position: relative;
    margin-bottom: 0.16rem;
}
.boxtitle img{
    width: 2.3rem;
    height: 0.54rem;
    position: absolute;
    top: 0.14rem;
    z-index: -1;
}
.gywmbox .gytxt{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.38rem;
    color: #343434;
    text-indent: 0.48rem;
    text-align: justify;
}


.gwitemlist{
    width: 100%;
    overflow: auto;
}
.gwitemlist::-webkit-scrollbar {
    display: none;
}
.gwitemlists{
    padding-top: 0.1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    .gwitemlistsbg{
        width: 1.22rem;
        height: 5.08rem;
        position: absolute;
        z-index: -1;
    }
    .gwitemlistsimg{
        width: 5.16rem;
        height: 3.44rem;
        // width: 1.32rem;
        // height: 1.86rem;
        margin: 0 auto 0.4rem;
        border-radius: 0.08rem;
        position: relative;
        z-index: 888;
    }
    .gwitemliststxt{
        text-align: justify;
        width: 7.02rem;
        font-size: 0.24rem;
        line-height: 0.36rem;
        color: #343434;
        margin: 0 auto 0.4rem;
        text-indent: 0.48rem;
    }
}
.gwitem{
    width: 2.88rem;
    height: 3.2rem;
}
.gwitemlist .gwitemlists .gwitem .imgbox{
    display: block;
    width: 1.9rem;
    height: 1.9rem;
    margin: 0 auto 0.06rem;
    border-radius: 50%;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
    }
}
.gwitemlist .gwitemlists .gwitem .gwname{
    width: 2.88rem;
    font-size: 0.28rem;
    font-family: FZCuHeiSongS-B-GB;
    font-weight: 400;
    line-height: 0.34rem;
    color: #444B54;
    text-align: center;
    margin-bottom: 0.08rem;
}
.gwitemlist .gwitemlists .gwitem .gwch{
    width: 2.88rem;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.32rem;
    color: #722E25;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 0.04rem;
}
.gwitemlist .gwitemlists .gwitem .gwch span{
    transform: scale(.92);
}


.fzlcbox{
    width: 100%;
    position: relative;
    overflow: hidden;
}
.fzlcbox .boxtitle{
    margin-left: 0.22rem;
}
.fzlcbox1{
    width: 100%;
    height: 5rem;
    position: relative;
    overflow: hidden;
    display: flex;
    margin-bottom: 0.6rem;
}
.fzlcbg{
    width: 7.26rem;
    height: 4.04rem;
    position: absolute;
    top: 1.14rem;
    left: 0.12rem;
}
.fzlcxian{
    width: 0.02rem;
    height: 5rem;
    background-color: #E2E2E6;
    margin-left: 0.64rem;
}
.fzlcbox1item{
    position: absolute;
    top: 0.24rem;
    left: 0.54rem;
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.42rem;
    color: #C5C5C5;
    display: flex;
    justify-content: space-between;
}
.fzlcbox1item .fzlcbox1itemdian{
    width: 0.24rem;
    height: 0.24rem;
    background: rgba(197, 197, 197, .51);
    border-radius: 50%;
    position: relative;
    margin-right: 0.24rem;
    margin-top: 0.09rem;
}
.fzlcbox1item .fzlcbox1itemdian div{
    width: 0.16rem;
    height: 0.16rem;
    background: #C5C5C5;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.fzlcbox1item2{
    top: 1.3rem;
}
.fzlcbox1item3{
    top: 2.3rem;
}
.fzlcbox1item4{
    top: 3.3rem;
}
.fzlcbox1item5{
    top: 4.3rem;
}
.fzlcbox1 .fzlcbox1itemactive{
    font-size: 0.32rem;
    color: #343434;
    left: 0.49rem;
}
.fzlcbox1 .fzlcbox1itemactive .fzlcbox1itemdian{
    width: 0.32rem;
    height: 0.32rem;
    background: rgba(226, 3, 9, .5);
    margin-right: 0.2rem;
    margin-top: 0.05rem;
}
.fzlcbox1 .fzlcbox1itemactive .fzlcbox1itemdian div{
    width: 0.2rem;
    height: 0.2rem;
    background: #A30001;
}
.fzlcbox1txt{
    width: 4.34rem;
    margin-top: 1rem;
    margin-left: 2rem;
}
.fzlcbox1txt p{
    font-size: 0.38rem;
    font-weight: 400;
    line-height: 0.5rem;
    color: #343434;
    text-align: center;
    margin-bottom: 0.34rem;
}
.fzlcbox1txt .fzlcbox1txts{
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.42rem;
    color: #343434;
    text-indent: 0.46rem;
    text-align: justify;
}


.gyyzbox2{
    width: 100%;
    background-color: #fff;
    padding: 0 4%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: .24rem;
}
.gyitem{
    width: 33%;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #595C6A;
    text-align: center;
}
.gyitem img{
    display: block;
    margin: 0 auto 0.08rem;
    width: 0.9rem;
    height: 0.9rem;
}
.gyitem .gyitemp{
    font-size: .24rem;
    font-weight: bold;
    color: #AE1F20;
    line-height: .34rem;
    text-align: center;
    margin-bottom: .24rem;
    transform: scale(1);
}
.gyitem p{
    transform: scale(.92);
}



.zytdbox{
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 0 0.22rem;
    box-sizing: border-box;
}
.zytdbox .boxtitle{
    margin-bottom: 0.16rem;
}
.contactUsList{
    border-bottom: 1px solid rgba(112, 112, 112, .2);
    overflow: hidden;
    
}
.contactUsList .contactUsItem{
    position: relative;
    border-bottom: 1px solid rgba(112, 112, 112, .2);
    overflow: hidden;
    padding-top: .24rem;
    box-sizing: border-box;
    height: 1.76rem;
    transition: .5s;

}
.contactUsList .contactUsItemHide{
    height: .84rem;
}
.contactUsList .contactUsItem .contactUsItemP1{
    font-size: .24rem;
    color: #343434;
    line-height: .38rem;
    margin-bottom: .14rem;
}
.contactUsList .contactUsItem .contactUsItemP2{
    font-size: .24rem;
    color: #8D8D8E;
    line-height: .38rem;
    margin-bottom: .12rem;

}
.contactUsList .contactUsItem .contactUsItemJT{
    width: .2rem;
    height: .06rem;
    display: block;
    position: absolute;
    right: .26rem;
    top: .38rem;
    transform: rotateZ(0deg);
    transition: .5s;
}
.contactUsList .contactUsItem .contactUsItemJTHide{
    transform: rotateZ(-180deg);
}
// .gwitemlist{
//     width: 100%;
//     overflow: auto;
//     padding: 0.08rem 0;
// }
// .gwitemlist::-webkit-scrollbar {
//     display: none;
// }
// .gwitemlists{
//     height: 3.66rem;
//     display: flex;
//     align-items: center;
// }
// .gwitem{
//     width: 2.78rem;
//     height: 3.66rem;
//     background: #FFFFFF;
//     box-shadow: 0px 0px 0.08rem rgba(0, 0, 0, 0.09);
//     border-radius: 0.08rem;
//     margin-left: 0.1rem;
//     position: relative;
// }
// .gwitemlist .gwitemlists .gwitem img{
//     display: block;
//     width: 1.6rem;
//     height: 1.6rem;
//     margin: 0.32rem auto 0.12rem;
//     position: relative;
//     z-index: 1;
// }
// .gwitemlist .gwitemlists .gwitem .gwname{
//     width: 2.78rem;
//     font-size: 0.34rem;
//     font-family: FZCuHeiSongS-B-GB;
//     font-weight: 400;
//     line-height: 0.42rem;
//     color: #444B54;
//     text-align: center;
//     margin-bottom: 0.2rem;
// }
// .gwitemlist .gwitemlists .gwitem .gwch{
//     width: 2.78rem;
//     font-size: 0.24rem;
//     font-weight: 400;
//     line-height: 0.32rem;
//     color: #722E25;
//     text-align: center;
//     margin-bottom: 0.04rem;
//     white-space: nowrap;
// }
// .gwitemlist .gwitemlists .gwitem .gwch span{
//     transform: scale(0.92);
// }
// .gwitemlist .gwitemlists .gwitem .tdbg{
//     width: 0.42rem;
//     height: 1.8rem;
//     position: absolute;
//     top: 0.24rem;
//     right: 0.32rem;
//     z-index: 0;
//     margin: 0;
// }



.cglcbox{
    width: 100%;
}
.cglcbox .boxtitle{
    margin-left: 0.2rem;
}
.cglcbox .cglcimg{
    width: 7.06rem;
    height: 3.08rem;
    margin: 0 auto 0.2rem;
}



.cgdybox{
    width: 100%;
    padding: 0 0.2rem;
    box-sizing: border-box;
}
.cgdyitembox{
    width: 100%;
    height: 5rem;
    overflow: auto;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #343434;
    text-align: justify;
    margin-bottom: 0.32rem;
    padding-right: 0.1rem;
    box-sizing: border-box;
}
.cgdyitembox::-webkit-scrollbar {
    width: 0.1rem;
    height: 0.02rem;
}
.cgdyitembox::-webkit-scrollbar-thumb {
    border-radius: 0.05rem;
     -webkit-box-shadow: inset 0 0 0.1rem rgba(0,0,0,0.2);
    background: #a0a0a0;
}
.cgdyitembox::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.1rem rgba(0,0,0,0.2);
    border-radius: 0.05rem;
    background: #EDEDED;
}
/* .cgdyitembox::-webkit-scrollbar {
    display: none;
} */
.cgdyitem .wen{
    display: flex;
    margin-bottom: 0.1rem;
}
.cgdyitem .wen img{
    display: block;
    width: 0.34rem;
    height: 0.34rem;
    margin-right: 0.16rem;
}
.cgdyitem .da{
    display: flex;
    margin-bottom: 0.1rem;
}
.cgdyitem .da img{
    display: block;
    width: 0.34rem;
    height: 0.34rem;
    margin-right: 0.16rem;
}








.newsbox{
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 0 0.22rem;
    box-sizing: border-box;
    margin-bottom: 0.2rem;
}
.newsbox .boxtitle{
    margin-top: 0.32rem;
    margin-bottom: 0.28rem;
}
.newsbox .newsitem{
    width: 100%;
    height: 1.7rem;
    
    
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
    
    border-bottom: 0.02rem solid rgba(221, 158, 117, 0.1411764705882353);
}
.newitmebox{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.newsbox .newsitem .newsitemleft{
    width: 4rem;
}
.newsbox .newsitem .newsitemleft .newstitle{
    width: 3.9rem;
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.4rem;
    color: #343434;
    margin-bottom: 0.1rem;
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 2;    
    overflow: hidden;

}
.newsbox .newsitem .newsitemleft .newstime{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.32rem;
    color: #B8B8B8;
    white-space: nowrap;
}
.newsbox .newsitem .newsitemleft .newstime span{
    display: inline-block;
    transform: scale(.84);
    transform-origin:0 0; 
}
.newsitemright{
    width: 2.88rem;
    height: 100%;
    overflow: hidden;
    border-radius: 0.08rem;
}
.newsitemright img{
    width: 100%;
    height: 100%;
}
.ysmore{
    display: block;
    width: 1.54rem;
    height: 0.52rem;
    background: #A30001;
    border-radius: 0.08rem;
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.52rem;
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto;
}

</style>